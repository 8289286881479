@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Mukta', 'WorkSans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 18.4px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

svg {
  vertical-align: baseline;
}

#__next {
  height: 100%;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

:root:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #c96341;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-box: #f5f5f5;
  --adm-color-background: #ffffff;
  --adm-font-size-main: 16px;
  --adm-font-family: 'Mukta', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.david-fade-out::after {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image : linear-gradient(to bottom, rgb(79, 74, 74, 0), rgba(79, 74, 74, 1) 90%);
  width: 100%;
  height: 160px;
}

.kelvin-fade-out::after {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image : linear-gradient(to bottom, rgb(90, 23, 2, 0), rgba(90, 23, 2, 1) 90%);
  width: 100%;
  height: 160px;
}

.ryan-fade-out::after {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image : linear-gradient(to bottom, rgba(56, 48, 46, 0), rgba(56, 48, 46, 1) 90%);
  width: 100%;
  height: 160px;
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
  border: 3px solid #5B5C6C;
}
