/* Mukta */
@font-face {
  font-family: "Mukta";
  src: url("/fonts/Mukta-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Mukta";
  src: url("/fonts/Mukta-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Mukta";
  src: url("/fonts/Mukta-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mukta";
  src: url("/fonts/Mukta-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Mukta";
  src: url("/fonts/Mukta-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Mukta";
  src: url("/fonts/Mukta-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mukta";
  src: url("/fonts/Mukta-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

/* Work Sans */
@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Italic-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}
