.svg {
  max-height: 100%;
  max-width: 100%;
  z-index: 10;
  position: absolute;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}